<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <k-text-input
            v-model="cruddataMX.conditions.userName"
            label="用户名"
          />
          <k-text-input
            v-model="cruddataMX.conditions.module"
            label="操作项目"
          />
          <k-text-input
            v-model="cruddataMX.conditions.operationType"
            label="动作"
          />
          <!-- <k-select
            v-model="cruddataMX.conditions.operationType"
            :items="operationTypeList"
            label="动作"
          /> -->
          <k-date-range-select
            v-model="cruddataMX.conditions.dates"
            label="日期选择"
          />
        </div>
        <k-primary-button
          class="my-1 mr-4"
          text="检索"
          icon="mdi-magnify"
          @click="crudConditionsMX().resetPage()"
        />
      </template>

      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #top>
            <v-toolbar flat dense>
              <v-toolbar-title>数据列表</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dates: [],
      operationTypeList: [
        { text: '编辑', value: '01' },
        { text: '删除', value: '02' },
        { text: '修改', value: '03' },
      ],
      crud: {
        name: '操作审计',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'operationModalForm',
        },
        default: {
          userName: '',
          module: '',
          operationType: '',
          operationStartTime: '',
          operationEndTime: '',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'indexNum',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作人员',
          value: 'accountName',
          sortable: false,
          align: 'center',
        },
        { text: '来源IP', value: 'ip', sortable: false, align: 'center' },
        { text: '操作项目', value: 'module', sortable: false, align: 'center' },
        {
          text: '动作',
          value: 'operationType',
          sortable: false,
          align: 'center',
        },
        {
          text: '时间',
          value: 'operationTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '描述',
          value: 'operationDetail',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  watch: {
    'cruddataMX.conditions.dates'(val) {
      if (val.length > 0) {
        this.cruddataMX.conditions.operationStartTime = val[0];
        this.cruddataMX.conditions.operationEndTime = val[1];
      } else {
        this.cruddataMX.conditions.operationStartTime = '';
        this.cruddataMX.conditions.operationEndTime = '';
      }
    },
  },
  created() {
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.system.operation.getOperationList,
      },
      {
        userName: '',
        module: '',
        operationType: '',
        dates: [],
        operationStartTime: '',
        operationEndTime: '',
      }
    );
  },
  methods: {},
};
</script>
